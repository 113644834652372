import {
  ref,
  computed,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useStore from '@/utils/store'
import useToast from '@/utils/toast'
import hiddenComptStoreModule from '@/store/tagby/hiddenCompt'

const title = ref()

export function useInitialize() {
  const initialize = () => {
    if (!store.hasModule(hiddenComptStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(hiddenComptStoreModule.STORE_MODULE_NAME, hiddenComptStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(hiddenComptStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(hiddenComptStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
  }
}

export function useInfoCard() {
  return {
    title,
  }
}

export function useCreateButton() {
  const { dispatch } = useStore(hiddenComptStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const isSaving = ref(false)
  const isValid = computed(() => Boolean(title.value))
  const createHiddenCompt = () => {
    isSaving.value = true
    dispatch('createHiddenCompt', {
      title: title.value,
    }).then(response => {
      router.replace({
        name: 'campaign-compt-hidden-detail',
        params: {
          idx: response.data.data.idx,
        },
      })
    }).catch(() => {
      makeToast('danger', '캠페인을 생성하는데 실패했습니다')
    }).finally(() => {
      isSaving.value = false
    })
  }
  return {
    isSaving,
    isValid,
    createHiddenCompt,
  }
}

export function others() {

}
