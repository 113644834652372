<template>
  <div>
    <BRow>
      <BCol cols="9">
        <ComptInfoCard />
      </BCol>

      <BCol cols="3">
        <ActionCard />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { useInitialize } from './viewModel'
import ComptInfoCard from './components/ComptInfoCard.vue'
import ActionCard from './components/ActionCard.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,

    ComptInfoCard,
    ActionCard,
  },
  setup() {
    const { initialize } = useInitialize()
    initialize()
  },
})
</script>
