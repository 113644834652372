<template>
  <BButton
    variant="outline-primary"
    block
    :disabled="isSaving || !isValid"
    @click="createHiddenCompt"
  >
    <BSpinner
      v-if="isSaving"
      small
    />
    캠페인 저장
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { useCreateButton } from '../viewModel'

export default defineComponent({
  components: {
    BButton,
    BSpinner,
  },
  setup() {
    const {
      isSaving,
      isValid,
      createHiddenCompt,
    } = useCreateButton()

    return {
      isSaving,
      isValid,
      createHiddenCompt,
    }
  },
})
</script>
